// MAINNET

// asset contracts
export const ARTBLOCKS_V1_ADDRESS =
  "0x059edd72cd353df5106d2b9cc5ab83a52287ac3a";
export const ARTBLOCKS_V2_ADDRESS =
  "0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270";
export const ARTBLOCKS_V3_ADDRESS =
  "0x99a9b7c1116f9ceeb1652de04d5969cce509b069";
export const ARTBLOCKS_EXPLORATIONS_ADDRESS =
  "0x942bc2d3e7a589fe5bd4a5c6ef9727dfd82f5c8a";
export const ARTBLOCKS_X_PACE_ADDRESS =
  "0x64780ce53f6e966e18a22af13a2f97369580ec11";
export const BRIGHT_MOMENTS_ADDRESS =
  "0x0a1bbd57033f57e7b6743621b79fcb9eb2ce3676";
export const BRIGHT_MOMENTS_CRYPTO_CITIZENS_ADDRESS =
  "0xbdde08bd57e5c9fd563ee7ac61618cb2ecdc0ce0";
export const TENNIS_ART_ADDRESS = "0x4d928ab507bf633dd8e68024a1fb4c99316bbdf3";
export const GRAILERS_IMPASTO_ADDRESS =
  "0x83ec6efb1bc4f479cb4161eb8217ac6ea7759135";
export const GMSTUDIO_APOLLO_ADDRESS =
  "0x8306fbf07c1bc59393dd9d3afc2a0ef97dfc7473";
export const GMSTUDIO_CATHARSIS_ADDRESS =
  "0x66293a9b1339ca99623e82bc71f88d767f60ad21";
export const GMSTUDIO_FACTURA_ADDRESS =
  "0x27787755137863bb7f2387ed34942543c9f24efe";
export const GMSTUDIO_KORIPO_ADDRESS =
  "0x1e725bcc09ad221d35af5adeda404fb2147b43fa";
export const GMSTUDIO_PLASTICITY_ADDRESS =
  "0x65d8b2bf930a0015028efcaee5af7bf61b90b76f";
export const GMSTUDIO_MIND_THE_GAP_ADDRESS =
  "0x0e42ffbac75bcc30cd0015f8aaa608539ba35fbb";
export const GMSTUDIO_BALAGAN_ADDRESS =
  "0x90e0ec77613fcca0f98ecfa7b51fbf0eee9d6a91";
export const PLOTTABLES_AB_ADDRESS =
  "0xa319c382a702682129fcbf55d514e61a16f97f9c";
export const TOYS_BY_0XTECHNO_ADDRESS =
  "0xeb7088423d7f8c1448ef074fc372bc67efa4de44";
export const ULTRA_DAO_ISLES_ADDRESS =
  "0xa708ababbf9cb9707ff352cf954767b3b5e6a216";
export const ENDAOMINT_AB_ADDRESS =
  "0xeafe7b73a3cfa804b761debcf077d4574588dfe7";
export const SOL_365_ADDRESS = "0x8a2cc211cbba1dc896d1939c4c43778a72d6362f";
export const GEN_ART_SOBEL = "0x603efd5b941022ad759e9b8eb40b890062746b46";
export const PROOF_GRAILS_1_ADDRESS =
  "0xb6329bd2741c4e5e91e26c4e653db643e74b2b19";
export const PROOF_GRAILS_2_ADDRESS =
  "0xd78afb925a21f87fa0e35abae2aead3f70ced96b";
export const PROOF_GRAILS_3_ADDRESS =
  "0x503a3039e9ce236e9a12e4008aecbb1fd8b384a3";
export const VCA_ADDRESS = "0x32d4be5ee74376e08038d652d4dc26e62c67f436";
export const BRAIN_DROPS_ADDRESS = "0xdfde78d2baec499fe18f2be74b6c287eed9511d7";

// auction contracts
export const ARTBLOCKS_DUTCH_AUCTION_ADDRESS =
  "0xD94C7060808f3c876824E57e685702f3834D2e13";
export const ARTBLOCKS_DUTCH_AUCTION_V2_ADDRESS =
  "0x706d6c6ef700a3c1c3a727f0c46492492e0a72b5";
export const ARTBLOCKS_DUTCH_AUCTION_V4_ADDRESS =
  "0x47e2df2723238f913741cc6b1963e76fdfd19b94";
export const ARTBLOCKS_X_PACE_DUTCH_AUCTION_ADDRESS =
  "0x12b7c521a4e4b988ce4ceb241872d620815e3b48";
export const ARTBLOCKS_FIXED_AUCTION_ADDRESS =
  "0x934cdc04C434b8dBf3E1265F4f198D70566f7355";
export const ARTBLOCKS_MERKLE_V2_AUCTION_ADDRESS =
  "0x6ff3c104ca9b4860d27079afff18701c4a532a4d";

// delegation registry v1 - same address across networks
export const DELEGATION_REGISTRY_ADDRESS =
  "0x00000000000076a84fef008cdabe6409d2fe638b";

// TESTNET

export const ARTBLOCKS_ADDRESS_GOERLI =
  "0xda62f67be7194775a75be91cbf9feedcc5776d4b";
export const GRAILERS_IMPASTO_GOERLI =
  "0xdb9bb2c975bde1792e86482335cfef72a871276c";

export const SAMPLE_COLLECTION_GOERLI =
  "0x3aafda7d679676dafd52628c775929c7a5dca985";
export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

// Art Blocks
export const ARTBLOCKS_CORE_V3_ENGINE_ADDRESS_SEPOLIA =
  "0x0e4e004e1f31b40bf4d7eefbd99d376a23065122";
export const ARTBLOCKS_CORE_V3_ENGINE_FLEX_ADDRESS_SEPOLIA =
  "0xfeba7e79d514d53ea937d1d3c1cb194b701dcfae";
export const ARTBLOCKS_CORE_V3_ADDRESS_SEPOLIA =
  "0xec5dae4b11213290b2dbe5295093f75920bd2982";

// Zora Auction Contract
// https://github.com/ourzora/v3/blob/main/contracts/modules/ReserveAuction/Listing/ETH/ReserveAuctionListingEth.sol
// https://github.com/ourzora/v3/blob/main/addresses/1.json
// Testnet only supports Goerli
export const ZORA_AUCTION_LISTING_ETH_ADDRESS_GOERLI =
  "0xfcebe0788d5772df2cbcf5079815de98a4d62b09";
export const ZORA_AUCTION_LISTING_ETH_ADDRESS_ETHEREUM =
  "0x1e58be778acae2744c1185ea1ce542f304860b96";
export const ZORA_AUCTION_TRANSFER_HELPER_ADDRESS_GOERLI =
  "0xd1adaf05575295710de1145c3c9427c364a70a7f";
export const ZORA_AUCTION_TRANSFER_HELPER_ADDRESS_ETHEREUM =
  "0x909e9efe4d87d1a6018c2065ae642b6d0447bc91";

export const ZORA_AUCTION_MODULE_MANAGER_ADDRESS_GOERLI =
  "0x9458e29713b98bf452ee9b2c099289f533a5f377";
export const ZORA_AUCTION_MODULE_MANAGER_ADDRESS_ETHEREUM =
  "0x850a7c6fe2cf48eea1393554c8a3ba23f20cc401";

// Marketplace Contracts
export const SUPERRARE_ADDRESS_1 = "0x41a322b28d0ff354040e2cbc676f0320d8c8850d";
export const SUPERRARE_ADDRESS_2 = "0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0";

// Labeled DB IDs
export const FRIENDSHIP_BRACELETS_ID = "635cd2cd268a7c04b9f29f5c";
export const YUCCA_ID = "637d1ca2346192a014a91c5d";
export const CHROMIE_SQUIGGLE_ID = "6305fd864ea89121ae4462a5";
export const RENDERS_GAME_ID = "63cffdc14bb65f232c08c3e5";
export const METROPOLIS_ID = "63eea14d6edd283c42eecf84";
export const TENNIS_ART_ID = "638f0e4add06fd7fc006f5d1";
export const GM_APOLLO_ID = "639c61331ea70cab2b97a5e0";
export const TOYS_ID = "63b19b6559c8fb4070858a9d";
export const INTERWOVEN_ID = "648192930ce4aa4e84976702";
export const BRAINDROPS_MINIPODS = "6401ca3a26a1d77cb47f4914";
export const BRAINDROPS_PODGANS = "6401c63526a1d77cb47e5803";
export const BRAINDROPS_CLIPMATRIX_CREATURES = "6401c97626a1d77cb47f1dfb";
export const BRAINDROPS_ELEMENTS = "6401c9f726a1d77cb47f3ae6";
export const BRAINDROPS_ALL_ART_LOOKS_SAME = "640c245cc726656508d37c3f";
export const BRAINDROPS_DETECTIVE_JACK = "65087eccb6529a9edcdb327d";
export const BRAINDROPS_VIEWS = "6568cdfc79d73e44d6108ad0";
export const MIRAGE_TOME_OF_AURUMS = "65808260c601deda726ae29d";
export const CRYPTO_VENITIAN = "6305fdcc4ea89121ae4463c3";
export const CRYPTO_BERLINER = "6390bf1c2d3d72171ca5abc0";
export const CRYPTO_TOKYOITE = "6451da8bcd4c7add1a78f647";
export const CRYPTO_LONDONER = "6390bf1d2d3d72171ca5abc5";
export const CRYPTO_MEXA = "6390bf1e2d3d72171ca5abca";
export const CRYPTO_GALACTICAN = "6390bf1b2d3d72171ca5abbb";
export const CRYPTO_NEWYORKER = "6305fde74ea89121ae4464ce";
export const MELANCHOLID_MAGICAL_MAIDEN_ID = "65d7183bef3a68a1dd6d1311";
export const NAMELESS_DREAD_HASURA_ID =
  "0xe18f2247fe4a69c0e2210331b0604f6d10fece9e-1";
export const DECISION_MATRIX_ID = "66602728896f6f663e190468";
export const RISK_REWARD_ID = "6660272874b2d0fb31c72d42";

// Wallet Labels
export const ART_BLOCKS_CORPORATE_WALLET_ADDRESS =
  "0x107054d474ec4995474bab1351f1da99029de638";

// Labeled Project IDs
export const BOKEH_PROJECT_ID =
  "0xab0000000000aa06f89b268d604a9c1c41524ac6-495";
export const BOKEH_LAMPS_PROJECT_ID =
  "0xab0000000000aa06f89b268d604a9c1c41524ac6-496";
export const BOKEH_MURALS_PROJECT_ID =
  "0xab0000000000aa06f89b268d604a9c1c41524ac6-494";
